import RichContent from '@pretto/bricks/website/about/components/RichContent'

import { type VideoSchemaType } from '@pretto/website/src/components/YoutubeVideo/types/videoSchema.type'
import { NewBlocRow } from '@pretto/website/src/templates/blocs/components/NewBlocRow'

import React from 'react'

import * as S from './VideoRow.styles'

export type VideoRowProps = {
  buttonLabel?: string
  buttonUrl?: string
  content?: string
  isButtonDisplayed?: boolean
  microdataSchema?: VideoSchemaType
  subtitle?: string
  title?: string
  video: string
}

export const VideoRow: React.FC<VideoRowProps> = ({
  buttonLabel,
  buttonUrl,
  content,
  isButtonDisplayed,
  microdataSchema,
  subtitle,
  title,
  video,
  ...props
}) => {
  const isButton = isButtonDisplayed && buttonUrl && buttonLabel
  const isOnlyVideo = !isButton && !title && !subtitle && !content

  return (
    <NewBlocRow>
      <S.Grid {...props}>
        <S.VideoWrapper $isOnlyVideo={isOnlyVideo}>
          <S.Video source={video} width={'100%'} microdataSchema={microdataSchema} />
        </S.VideoWrapper>

        {!isOnlyVideo && (
          <S.ContentWrapper>
            {subtitle && <S.Suptitle>{subtitle}</S.Suptitle>}

            {title && <S.Title>{title}</S.Title>}

            {content && <RichContent allowedTypes={['a', 'strong']}>{content}</RichContent>}

            {isButton && <S.Button href={buttonUrl}>{buttonLabel}</S.Button>}
          </S.ContentWrapper>
        )}
      </S.Grid>
    </NewBlocRow>
  )
}
