import { useVideoMicroData } from '@pretto/website/src/components/YoutubeVideo/lib/useVideoMicroData'
import { VideoRow, VideoRowProps } from '@pretto/website/src/templates/blocs/rows/rowVideo/components/VideoRow'

type RowVideoContainerProps = Omit<VideoRowProps, 'microdataSchema'>

export const RowVideoContainer: React.FC<RowVideoContainerProps> = props => {
  const microdataSchema = useVideoMicroData(props.video)

  return <VideoRow microdataSchema={microdataSchema} {...props} />
}
